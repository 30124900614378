import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// ====== Image Imports (Adapted to 2023) ======

//Preparatory Program for Certified Internal Auditor (CIA) Certification
import prjImg1 from "../../images/training_programs_2023/Preparatory Program for Certified Internal Auditor (CIA) Certification/image1.webp";
import prjImg2 from "../../images/training_programs_2023/Preparatory Program for Certified Internal Auditor (CIA) Certification/image2.webp";

//Practical Procedures...
import prjImg3 from "../../images/training_programs_2023/Practical Procedures for Implementing Administrative, Operational, and Analytical Audits According to International Standards/image1.webp";
import prjImg4 from "../../images/training_programs_2023/Practical Procedures for Implementing Administrative, Operational, and Analytical Audits According to International Standards/image2.webp";
import prjImg5 from "../../images/training_programs_2023/Practical Procedures for Implementing Administrative, Operational, and Analytical Audits According to International Standards/image3.webp";
import prjImg6 from "../../images/training_programs_2023/Practical Procedures for Implementing Administrative, Operational, and Analytical Audits According to International Standards/image4.webp";
import prjImg7 from "../../images/training_programs_2023/Practical Procedures for Implementing Administrative, Operational, and Analytical Audits According to International Standards/image5.webp";
import prjImg18 from "../../images/training_programs_2023/Practical Procedures for Implementing Administrative, Operational, and Analytical Audits According to International Standards/image6.webp";
import prjImg19 from "../../images/training_programs_2023/Practical Procedures for Implementing Administrative, Operational, and Analytical Audits According to International Standards/image7.webp";
import prjImg20 from "../../images/training_programs_2023/Practical Procedures for Implementing Administrative, Operational, and Analytical Audits According to International Standards/image8.webp";

//Financial Analysis...
import prjImg8 from "../../images/training_programs_2023/Financial Analysis and Its Role in Detecting Fraud and Manipulation in Financial Statements/image1.webp";
import prjImg9 from "../../images/training_programs_2023/Financial Analysis and Its Role in Detecting Fraud and Manipulation in Financial Statements/image2.webp";
import prjImg10 from "../../images/training_programs_2023/Financial Analysis and Its Role in Detecting Fraud and Manipulation in Financial Statements/image3.webp";
import prjImg11 from "../../images/training_programs_2023/Financial Analysis and Its Role in Detecting Fraud and Manipulation in Financial Statements/image4.webp";
import prjImg21 from "../../images/training_programs_2023/Financial Analysis and Its Role in Detecting Fraud and Manipulation in Financial Statements/image5.webp";
import prjImg22 from "../../images/training_programs_2023/Financial Analysis and Its Role in Detecting Fraud and Manipulation in Financial Statements/image6.webp";

//Marketing Communication Course
import prjImg12 from "../../images/training_programs_2023/Marketing Communication Course/image1.webp";
import prjImg13 from "../../images/training_programs_2023/Marketing Communication Course/image2.webp";
import prjImg24 from "../../images/training_programs_2023/Marketing Communication Course/image3.jpg";
import prjImg25 from "../../images/training_programs_2023/Marketing Communication Course/image4.jpg";

//Financial Accounting From Income Statement Perspective
import prjImg15 from "../../images/training_programs_2023/Financial Accounting From Income Statement Perspective/image1.webp";
import prjImg16 from "../../images/training_programs_2023/Financial Accounting From Income Statement Perspective/image2.webp";
import prjImg17 from "../../images/training_programs_2023/Financial Accounting From Income Statement Perspective/image3.webp";
import prjImg23 from "../../images/training_programs_2023/Financial Accounting From Income Statement Perspective/image4.jpg";


// ====== Preload images to avoid flicker ======
const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

const Trainigprograms2023 = () => {
  const { t, i18n } = useTranslation();

  // EXACT same logic, but referencing "trainingPrograms2023"
  const projects = [
    {
      images: [prjImg1, prjImg2],
      title: t("trainingPrograms2023.cia.title"),
      description: t("trainingPrograms2023.cia.description", { returnObjects: true }),
      sections: t("trainingPrograms2023.cia.sections", { returnObjects: true }),
      location: t("trainingPrograms2023.cia.location"),
      date: t("trainingPrograms2023.cia.date"),
      duration: t("trainingPrograms2023.cia.duration"),
    },
    {
      images: [prjImg4, prjImg3, prjImg5, prjImg6, prjImg7, prjImg18, prjImg19, prjImg20],
      title: t("trainingPrograms2023.practicalProcedures.title"),
      description: t("trainingPrograms2023.practicalProcedures.description", {
        returnObjects: true,
      }),
      location: t("trainingPrograms2023.practicalProcedures.location"),
      date: t("trainingPrograms2023.practicalProcedures.date"),
      duration: t("trainingPrograms2023.practicalProcedures.duration"),
    },
    {
      images: [prjImg8, prjImg9, prjImg10, prjImg11, prjImg21, prjImg22],
      title: t("trainingPrograms2023.financialAnalysis.title"),
      description: t("trainingPrograms2023.financialAnalysis.description", {
        returnObjects: true,
      }),
      location: t("trainingPrograms2023.financialAnalysis.location"),
      date: t("trainingPrograms2023.financialAnalysis.date"),
      duration: t("trainingPrograms2023.financialAnalysis.duration"),
    },
    {
      images: [prjImg13, prjImg12, prjImg24, prjImg25],
      title: t("trainingPrograms2023.marketingCommunication.title"),
      description: t("trainingPrograms2023.marketingCommunication.description", {
        returnObjects: true,
      }),
      location: t("trainingPrograms2023.marketingCommunication.location"),
      date: t("trainingPrograms2023.marketingCommunication.date"),
      duration: t("trainingPrograms2023.marketingCommunication.duration"),
    },
    {
      images: [prjImg16, prjImg15, prjImg17, prjImg23],
      title: t("trainingPrograms2023.financialAccounting.title"),
      description: t("trainingPrograms2023.financialAccounting.description", {
        returnObjects: true,
      }),
      sections: t("trainingPrograms2023.financialAccounting.sections", { returnObjects: true }),
      location: t("trainingPrograms2023.financialAccounting.location"),
      date: t("trainingPrograms2023.financialAccounting.date"),
      duration: t("trainingPrograms2023.financialAccounting.duration"),
    },
  ];

  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  // Preload all images on mount
  useEffect(() => {
    const allImages = projects.flatMap((proj) => proj.images || []);
    preloadImages(allImages);
  }, [projects]);

  const handlePreviousImage = () => {
    if (!isFading) {
      setIsFading(true);
      setTimeout(() => {
        setMainImageIndex((prev) =>
          prev === 0
            ? projects[currentProjectIndex].images.length - 1
            : prev - 1
        );
        setIsFading(false);
      }, 250);
    }
  };

  const handleNextImage = () => {
    if (!isFading) {
      setIsFading(true);
      setTimeout(() => {
        setMainImageIndex((prev) =>
          (prev + 1) % projects[currentProjectIndex].images.length
        );
        setIsFading(false);
      }, 250);
    }
  };

  const handlePreviousProject = () => {
    setCurrentProjectIndex((prev) =>
      prev === 0 ? projects.length - 1 : prev - 1
    );
    setMainImageIndex(0);
  };

  const handleNextProject = () => {
    setCurrentProjectIndex((prev) =>
      (prev + 1) % projects.length
    );
    setMainImageIndex(0);
  };

  // Grab current project's data
  const { images, title, description, location, sections, date, duration } =
    projects[currentProjectIndex];

  // EXACT same text-direction approach
  const textDirectionStyle = {
    direction: i18n.language === "ar" ? "rtl" : "ltr",
    textAlign: i18n.language === "ar" ? "right" : "left",
  };

  return (
    <section className="wpo-project-single-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="wpo-project-single-wrap">
              <div className="wpo-project-single-content">
                {/* Image Slider */}
                <div className="main-image-container">
                  <button className="slider-arrow left-arrow" onClick={handlePreviousImage}>
                    &larr;
                  </button>
                  <img
                    src={images[mainImageIndex]}
                    alt={`Project Slide ${mainImageIndex + 1}`}
                    className={`main-slider-image ${isFading ? "fade-out" : "fade-in"}`}
                  />
                  <button className="slider-arrow right-arrow" onClick={handleNextImage}>
                    &rarr;
                  </button>
                </div>

                {/* Project Details */}
                <div className="wpo-project-single-content-des">
                  <div className="wpo-project-single-content-des-wrap">
                    {/* LEFT TEXT (title, description, sections) */}
                    <div className="wpo-project-single-content-des-left" style={textDirectionStyle}>
                      <h2>{title}</h2>

                      {description &&
                        description.map((para, idx) => <p key={idx}>{para}</p>)}

                      {sections &&
                        sections.map((section, sIndex) => (
                          <div key={sIndex} style={{ marginBottom: "20px" }}>
                            {section.header && (
                              <h3 style={{ marginBottom: "10px" }}>{section.header}</h3>
                            )}
                            {section.bulletPoints && (
                              <ul
                                style={{
                                  listStyleType: "disc",
                                  paddingInlineStart: "1.5rem",
                                }}
                              >
                                {section.bulletPoints.map((point, pIndex) =>
                                  typeof point === "object" && point.subPoints ? (
                                    <li key={pIndex}>
                                      {point.mainPoint}
                                      <ul style={{ paddingInlineStart: "1.5rem" }}>
                                        {point.subPoints.map((sp, spIdx) => (
                                          <li key={spIdx}>{sp}</li>
                                        ))}
                                      </ul>
                                    </li>
                                  ) : (
                                    <li key={pIndex}>{point}</li>
                                  )
                                )}
                              </ul>
                            )}
                          </div>
                        ))}
                    </div>

                    {/* RIGHT TEXT (location, date, duration) */}
                    <div className="wpo-project-single-content-des-right">
                      <ul style={textDirectionStyle}>
                        <li>
                          {t("locationLabel")}: <span>{location}</span>
                        </li>
                        <li>
                          {t("dateLabel")}: <span>{date}</span>
                        </li>
                        <li>
                          {t("durationLabel")}: <span>{duration}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* Nav Buttons (Identical to 2024) */}
              <div className="more-posts">
                {/* Left button: arrow, then text */}
                <button onClick={handlePreviousProject} className="arrow-btn prev-btn">
                  &larr; {t("previousLabel")}
                </button>
                {/* Right button: text, then arrow */}
                <button onClick={handleNextProject} className="arrow-btn next-btn">
                  {t("nextLabel")} &rarr;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Inline CSS exactly as in 2024 */}
      <style>{`
        .main-image-container {
          position: relative;
          width: 100%;
          max-height: 500px;
          overflow: hidden;
          border-radius: 15px;
        }

        .main-slider-image {
          width: 100%;
          height: 500px;
          object-fit: cover;
          border-radius: 15px;
          opacity: 1;
          transition: opacity 0.5s ease-in-out;
        }

        .main-slider-image.fade-out {
          opacity: 0;
        }

        .main-slider-image.fade-in {
          opacity: 1;
        }

        .slider-arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background: rgba(0, 0, 0, 0.5);
          color: white;
          border: none;
          padding: 10px 20px;
          cursor: pointer;
          font-size: 18px;
          border-radius: 5px;
        }

        .slider-arrow.left-arrow {
          left: 10px;
        }

        .slider-arrow.right-arrow {
          right: 10px;
        }

        .slider-arrow:hover {
          background: rgba(0, 0, 0, 0.8);
        }

        .arrow-btn {
          background: linear-gradient(145deg, #003366, #004080);
          color: white;
          border: none;
          padding: 12px 24px;
          border-radius: 25px;
          font-size: 16px;
          cursor: pointer;
        }
            /* Left Content: Title, Description, Sections */
  .wpo-project-single-content-des-left p,
  .wpo-project-single-content-des-left ul,
  
  /* Right Content: Date, Location, Duration */
  .wpo-project-single-content-des-right ul,
  .wpo-project-single-content-des-right li {
    font-size: 20px !important; /* Increase font size */
    line-height: 1.6; /* Adjust line spacing */
  }

        .more-posts {
          display: flex;
          justify-content: space-between;
          margin-top: 30px;
        }
      `}</style>
    </section>
  );
};

export default Trainigprograms2023;
