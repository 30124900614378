import React from "react";
import { useTranslation } from "react-i18next";
import trainingCover from "../../images/trainingplan.jpg";
import trainingPlanPDF from "../../files/Mafaz_Training_plan_2025.pdf";
import "./UpcomingTraining.css";

const UpcomingTraining = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar"; // Check if the language is Arabic

  const programs = [
    {
      title: t("upcomingTraining.programs.0.title"),
      location: t("upcomingTraining.programs.0.location"),
      date: t("upcomingTraining.programs.0.date"),
    },
    {
      title: t("upcomingTraining.programs.1.title"),
      location: t("upcomingTraining.programs.1.location"),
      date: t("upcomingTraining.programs.1.date"),
    },
    {
      title: t("upcomingTraining.programs.2.title"),
      location: t("upcomingTraining.programs.2.location"),
      date: t("upcomingTraining.programs.2.date"),
    },
    {
      title: t("upcomingTraining.programs.3.title"),
      location: t("upcomingTraining.programs.3.location"),
      date: t("upcomingTraining.programs.3.date"),
    },
    {
      title: t("upcomingTraining.programs.4.title"),
      location: t("upcomingTraining.programs.4.location"),
      date: t("upcomingTraining.programs.4.date"),
    },
  ];

  return (
    <section
      className="training-section"
      style={{
        direction: isRTL ? "rtl" : "ltr", // Dynamically set direction
      }}
    >
      <div className="container-fluid">
        <div
          className="row align-items-center"
          style={{
            flexDirection: isRTL ? "row-reverse" : "row", // Reverse row order for RTL
          }}
        >
          {/* Training Cover Section */}
          <div
            className="col-lg-5"
            style={{
              textAlign: isRTL ? "right" : "left", // Dynamically align text
              marginBottom: "20px",
            }}
          >
            <div className="training-cover">
              {/* Wrap image and text inside an anchor tag */}
              <a
                href={trainingPlanPDF}
                download="Mafaz_Training_plan_2025"
                style={{
                  display: "inline-block",
                  textDecoration: "none",
                  cursor: "pointer", // Explicit pointer cursor
                }}
              >
                <img
                  src={trainingCover}
                  alt="Training Plan 2025"
                  style={{
                    maxWidth: "100%",
                    borderRadius: "10px",
                    cursor: "pointer", // Pointer cursor for the image
                  }}
                />
                <h2
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    fontSize: "18px",
                    color: "#003366",
                    cursor: "pointer", // Pointer cursor for text
                  }}
                />
                <h2
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    fontSize: "18px",
                    color: "#003366",
                  }}
                >
                  {t("upcomingTraining.getPlan")}
                </h2>
              </a>
            </div>
          </div>

          {/* Programs Table */}
          <div
            className="col-lg-7"
            style={{
              textAlign: isRTL ? "left" : "right", // Dynamically align text
            }}
          >
            <div className="training-programs">
              <h3
                style={{
                  textAlign: isRTL ? "right" : "left", // Adjust heading alignment
                }}
              >
                {t("upcomingTraining.title")}
              </h3>
              <ul className="program-list">
                {programs.map((program, index) => (
                  <li
                    key={index}
                    className="program-item"
                    style={{
                      textAlign: isRTL ? "right" : "left",
                      direction: isRTL ? "rtl" : "ltr", // Set direction dynamically
                    }}
                  >
                    <span className="program-title">{program.title}</span>
                    <span className="program-location">{program.location}</span>
                    <span className="program-date">{program.date}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpcomingTraining;
