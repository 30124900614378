import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../../components/Navbar';
import Hero2 from '../../components/hero2';
import VideoBanner from '../../components/HomePageVideo'; // Ensure correct path
import Support from '../../components/Support';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';
import WhoWeAre from '../../components/Whoarewe';
import UpcomingTraining from '../../components/trainingplan';

const HomePage2 = () => {
  const { i18n } = useTranslation();
  const isRTL = i18n.language === 'ar'; // Check if the current language is Arabic

  return (
    <Fragment>
      <div
        dir={isRTL ? 'rtl' : 'ltr'}
        style={{
          direction: isRTL ? 'rtl' : 'ltr', // Set direction
        }}
      >
        <Navbar />
        <Hero2 />
        <WhoWeAre />
        <VideoBanner />
        <UpcomingTraining />
        <Support />
        <Footer />
        <Scrollbar />
      </div>
    </Fragment>
  );
};

export default HomePage2;
    