// components/VideoBanner.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './VideoBanner.css'; // Import your CSS for styling

const VideoBanner = () => {
  const { i18n } = useTranslation();

  // Use both i18n.language and i18n.resolvedLanguage (if available)
  const currentLang = i18n.language || i18n.resolvedLanguage || 'en';
  const isArabic = currentLang.startsWith('ar');
  const videoUrl = isArabic ? '/Videos/arabic_version.mp4' : '/Videos/english_version.mp4';

  return (
    <div className="video-section">
      <div className="video-container">
        {/* Setting key={currentLang} forces a remount when language changes */}
        <video
          key={currentLang}
          controls
          autoPlay
          loop
          muted
          className="cultural-video"
          aria-label={i18n.t('video.accessibilityLabel')}
        >
          <source src={videoUrl} type="video/mp4" />
          {i18n.t('video.unsupported')}
        </video>
      </div>
    </div>
  );
};

export default VideoBanner;
