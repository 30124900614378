import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../../images/logo-white.webp';

const Footer = () => {
  const { t } = useTranslation(); // Hook for translations

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <footer
      className="wpo-site-footer"
      // Force the footer to use LTR and center alignment
      style={{ direction: 'ltr', textAlign: 'center' }}
    >
      <div className="wpo-upper-footer">
        <div className="container">
          <div
            className="row"
            style={{
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            {/* Left section with Logo and About text */}
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div 
                className="widget about-widget"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <div
                  className="logo widget-title"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '20px',
                  }}
                >
                  <Link onClick={ClickHandler} to="/">
                    <img
                      src={Logo}
                      alt={t('footer.logoAlt')}
                      style={{ maxWidth: '100%' }}
                    />
                  </Link>
                </div>
                <p>{t('footer.about')}</p>
              </div>
            </div>

            {/* Middle section with Contact information */}
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div
                  className="widget-title"
                  style={{ textAlign: 'center', marginBottom: '20px' }}
                >
                  <h3
                    style={{ fontWeight: 'bold', textAlign: 'center', width: '100%' }}
                  >
                    {t('footer.contactTitle')}
                  </h3>
                </div>
                <div className="contact-ft">
                  <ul style={{ listStyle: 'none', padding: 0 }}>
                    <li style={{ marginBottom: '10px' }}>
                      <i
                        className="fi flaticon-location"
                        style={{ marginRight: '8px' }}
                      ></i>
                      {t('footer.contact.address')}
                    </li>
                    <li style={{ marginBottom: '10px' }}>
                      <i
                        className="fi flaticon-phone-call"
                        style={{ marginRight: '8px' }}
                      ></i>
                      {t('footer.contact.phone1')}
                    </li>
                    <li style={{ marginBottom: '10px' }}>
                      <i
                        className="fi flaticon-phone-call"
                        style={{ marginRight: '8px' }}
                      ></i>
                      {t('footer.contact.phone3')}
                    </li>
                    <li style={{ marginBottom: '10px' }}>
                      <i
                        className="fi flaticon-send"
                        style={{ marginRight: '8px' }}
                      ></i>
                      {t('footer.contact.email')}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Right section with Follow Us and Social Media icons */}
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget social-widget">
                <div
                  className="widget-title"
                  style={{ textAlign: 'center', marginBottom: '20px' }}
                >
                  <h3
                    style={{ fontWeight: 'bold', textAlign: 'center', width: '100%' }}
                  >
                    {t('footer.followUs')}
                  </h3>
                </div>
                <ul
                  className="social-links"
                  style={{
                    display: 'flex',
                    gap: '15px',
                    justifyContent: 'center',
                    listStyle: 'none',
                    padding: 0,
                  }}
                >
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      <i className="ti-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      <i className="ti-twitter-alt"></i>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      <i className="ti-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/mafaz-tc/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="ti-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12" style={{ textAlign: 'center' }}>
              <p className="copyright">
                {t('footer.copyright')}{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
