import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/pagetitle';
import Contactpage from '../../components/Contactpage';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

const ContactPage = () => {
  const { t, i18n } = useTranslation(); // Hook for translations
  const isRTL = i18n.language === 'ar'; // Check if the language is Arabic

  return (
    <Fragment>
      <Navbar />
      <PageTitle
        pageTitle={t('contactusPageTitle')}
        pagesub={t('contactusPageSubtitle')}
        isRTL={isRTL} // Pass RTL flag specifically for title and subtitle
      />
      <Contactpage />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default ContactPage;
