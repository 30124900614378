import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PageTitle = (props) => {
  const { t, i18n } = useTranslation(); // Get translation and language
  const isRTL = i18n.language === 'ar'; // Check if the current language is Arabic

  return (
    <div className="wpo-breadcumb-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="wpo-breadcumb-wrap">
              {/* Title with dynamic alignment and direction */}
              <h2
                style={{
                  textAlign: isRTL ? 'right' : 'left',
                  direction: isRTL ? 'rtl' : 'ltr',
                }}
              >
                {props.pageTitle}
              </h2>

              {/* Subtitle list with dynamic alignment and direction */}
              <ul
                style={{
                  display: 'flex',
                  listStyleType: 'none',
                  padding: 0,
                  margin: 0,
                  textAlign: isRTL ? 'right' : 'left',
                  direction: isRTL ? 'rtl' : 'ltr',
                }}
              >
                <li style={{ marginRight: isRTL ? '0' : '10px', marginLeft: isRTL ? '10px' : '0' }}>
                  <Link to="/home">{t('homeLink')}</Link>
                  <span>&nbsp;&nbsp;</span>
                </li>

                <li>
                  <span
                    style={{
                      unicodeBidi: 'embed',
                      direction: isRTL ? 'rtl' : 'ltr',
                      paddingLeft: isRTL ? '0' : '10px',
                      paddingRight: isRTL ? '10px' : '0',
                    }}
                  >
                    {props.pagesub}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
