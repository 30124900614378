import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// ====== Image Imports (2022) ======

// Protection Plan Procedures for Critical Facilities
import prjImg1 from "../../images/training_programs_2022/Protection Plan Procedures for Critical Facilities/image1.webp";
import prjImg2 from "../../images/training_programs_2022/Protection Plan Procedures for Critical Facilities/image2.webp";
import prjImg3 from "../../images/training_programs_2022/Protection Plan Procedures for Critical Facilities/image3.webp";

// Fundamentals of Off-Road Driving and Handling Emergency Mechanical Failures
import prjImg4 from "../../images/training_programs_2022/Fundamentals of Off-Road Driving and Handling Emergency Mechanical Failures/image1.webp";
import prjImg5 from "../../images/training_programs_2022/Fundamentals of Off-Road Driving and Handling Emergency Mechanical Failures/image2.webp";
import prjImg6 from "../../images/training_programs_2022/Fundamentals of Off-Road Driving and Handling Emergency Mechanical Failures/image3.webp";

// Finance for non Finance Manager
import prjImg7 from "../../images/training_programs_2022/Finance for non Finance Manager/image1.webp";
import prjImg8 from "../../images/training_programs_2022/Finance for non Finance Manager/image2.webp";
import prjImg9 from "../../images/training_programs_2022/Finance for non Finance Manager/image3.webp";

// Financial Accounting Skills
import prjImg10 from "../../images/training_programs_2022/Financial Accounting Skills/image1.webp";
import prjImg11 from "../../images/training_programs_2022/Financial Accounting Skills/image2.webp";
import prjImg12 from "../../images/training_programs_2022/Financial Accounting Skills/image3.jpg";

// ===== Helper: Preload images to avoid flicker =====
const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

const Trainigprograms2022 = () => {
  const { t, i18n } = useTranslation();

  // === EXACT same structure as 2024, referencing "trainingPrograms2022" ===
  const projects = [
    {
      images: [prjImg1, prjImg2, prjImg3],
      title: t("trainingPrograms2022.protectionPlan.title"),
      description: t("trainingPrograms2022.protectionPlan.description", {
        returnObjects: true,
      }),
      location: t("trainingPrograms2022.protectionPlan.location"),
      date: t("trainingPrograms2022.protectionPlan.date"),
      duration: t("trainingPrograms2022.protectionPlan.duration"),
    },
    {
      images: [prjImg6, prjImg5, prjImg4],
      title: t("trainingPrograms2022.offRoad.title"),
      description: t("trainingPrograms2022.offRoad.description", {
        returnObjects: true,
      }),
      location: t("trainingPrograms2022.offRoad.location"),
      date: t("trainingPrograms2022.offRoad.date"),
      duration: t("trainingPrograms2022.offRoad.duration"),
    },
    {
      images: [prjImg7, prjImg8, prjImg9],
      title: t("trainingPrograms2022.financeForNonFinance.title"),
      description: t("trainingPrograms2022.financeForNonFinance.description", {
        returnObjects: true,
      }),
      sections: t("trainingPrograms2022.financeForNonFinance.sections", {
        returnObjects: true,
      }),
      location: t("trainingPrograms2022.financeForNonFinance.location"),
      date: t("trainingPrograms2022.financeForNonFinance.date"),
      duration: t("trainingPrograms2022.financeForNonFinance.duration"),
    },
    {
      images: [prjImg10, prjImg11, prjImg12],
      title: t("trainingPrograms2022.financialAccounting.title"),
      description: t("trainingPrograms2022.financialAccounting.description", {
        returnObjects: true,
      }),
      sections: t("trainingPrograms2022.financialAccounting.sections", {
        returnObjects: true,
      }),
      location: t("trainingPrograms2022.financialAccounting.location"),
      date: t("trainingPrograms2022.financialAccounting.date"),
      duration: t("trainingPrograms2022.financialAccounting.duration"),
    },
    {
      title: t("trainingPrograms2022.advancedPMP.title"),
      description: t("trainingPrograms2022.advancedPMP.description", {
        returnObjects: true,
      }),
      sections: t("trainingPrograms2022.advancedPMP.sections", {
        returnObjects: true,
      }),
      location: t("trainingPrograms2022.advancedPMP.location"),
      date: t("trainingPrograms2022.advancedPMP.date"),
      duration: t("trainingPrograms2022.advancedPMP.duration"),
    },
    {
      title: t("trainingPrograms2022.brandManagement.title"),
      description: t("trainingPrograms2022.brandManagement.description", {
        returnObjects: true,
      }),
      sections: t("trainingPrograms2022.brandManagement.sections", {
        returnObjects: true,
      }),
      location: t("trainingPrograms2022.brandManagement.location"),
      date: t("trainingPrograms2022.brandManagement.date"),
      duration: t("trainingPrograms2022.brandManagement.duration"),
    },
  ];

  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  // === Preload all project images on mount ===
  useEffect(() => {
    const allImages = projects.flatMap((project) => project.images || []);
    preloadImages(allImages);
  }, [projects]);

  const handlePreviousImage = () => {
    if (!isFading) {
      setIsFading(true);
      setTimeout(() => {
        setMainImageIndex((prevIndex) =>
          prevIndex === 0
            ? projects[currentProjectIndex].images.length - 1
            : prevIndex - 1
        );
        setIsFading(false);
      }, 250); // fade-out timing
    }
  };

  const handleNextImage = () => {
    if (!isFading) {
      setIsFading(true);
      setTimeout(() => {
        setMainImageIndex(
          (prevIndex) =>
            (prevIndex + 1) % projects[currentProjectIndex].images.length
        );
        setIsFading(false);
      }, 250); // half the transition duration
    }
  };

  const handlePreviousProject = () => {
    setCurrentProjectIndex((prevIndex) =>
      prevIndex === 0 ? projects.length - 1 : prevIndex - 1
    );
    setMainImageIndex(0);
  };

  const handleNextProject = () => {
    setCurrentProjectIndex((prevIndex) =>
      (prevIndex + 1) % projects.length
    );
    setMainImageIndex(0);
  };

  // === current project data ===
  const { images, title, description, sections, location, date, duration } =
    projects[currentProjectIndex];

  // === EXACT same text-direction approach as 2024 ===
  const textDirectionStyle = {
    direction: i18n.language === "ar" ? "rtl" : "ltr",
    textAlign: i18n.language === "ar" ? "right" : "left",
  };

  return (
    <section className="wpo-project-single-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="wpo-project-single-wrap">
              <div className="wpo-project-single-content">
                {/* IMAGE SLIDER (identical logic) */}
                {images && images.length > 0 ? (
                  <div className="main-image-container">
                    <button
                      className="slider-arrow left-arrow"
                      onClick={handlePreviousImage}
                    >
                      &larr;
                    </button>
                    <img
                      src={images[mainImageIndex]}
                      alt={`Project Slide ${mainImageIndex + 1}`}
                      className={`main-slider-image ${
                        isFading ? "fade-out" : "fade-in"
                      }`}
                    />
                    <button
                      className="slider-arrow right-arrow"
                      onClick={handleNextImage}
                    >
                      &rarr;
                    </button>
                  </div>
                ) : (
                  <p style={{ textAlign: "center", padding: "20px" }}>
                  </p>
                )}

                {/* PROJECT DETAILS */}
                <div className="wpo-project-single-content-des">
                  <div className="wpo-project-single-content-des-wrap">
                    {/* LEFT TEXT */}
                    <div
                      className="wpo-project-single-content-des-left"
                      style={textDirectionStyle}
                    >
                      <h2>{title}</h2>

                      {description &&
                        description.map((para, idx) => (
                          <p key={idx}>{para}</p>
                        ))}

                      {sections &&
                        sections.map((section, sIndex) => (
                          <div key={sIndex} style={{ marginBottom: "20px" }}>
                            {section.header && (
                              <h3 style={{ marginBottom: "10px" }}>
                                {section.header}
                              </h3>
                            )}
                            {section.bulletPoints && (
                              <ul
                                style={{
                                  listStyleType: "disc",
                                  paddingInlineStart: "1.5rem",
                                }}
                              >
                                {section.bulletPoints.map((point, pIndex) =>
                                  typeof point === "object" && point.subPoints ? (
                                    <li key={pIndex}>
                                      {point.mainPoint}
                                      <ul style={{ paddingInlineStart: "1.5rem" }}>
                                        {point.subPoints.map((sp, spIdx) => (
                                          <li key={spIdx}>{sp}</li>
                                        ))}
                                      </ul>
                                    </li>
                                  ) : (
                                    <li key={pIndex}>{point}</li>
                                  )
                                )}
                              </ul>
                            )}
                          </div>
                        ))}
                    </div>

                    {/* RIGHT TEXT (Location, Date, Duration) */}
                    <div className="wpo-project-single-content-des-right">
                      <ul style={textDirectionStyle}>
                        <li>
                          {t("locationLabel")}: <span>{location}</span>
                        </li>
                        <li>
                          {t("dateLabel")}: <span>{date}</span>
                        </li>
                        <li>
                          {t("durationLabel")}: <span>{duration}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* NAV BUTTONS EXACTLY LIKE 2023/2024 */}
              <div className="more-posts">
                <button onClick={handlePreviousProject} className="arrow-btn prev-btn">
                  &larr; {t("previousLabel")}
                </button>
                <button onClick={handleNextProject} className="arrow-btn next-btn">
                  {t("nextLabel")} &rarr;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Inline CSS identical to 2024 */}
      <style>{`
        .main-image-container {
          position: relative;
          width: 100%;
          max-height: 500px;
          overflow: hidden;
          border-radius: 15px;
        }

        .main-slider-image {
          width: 100%;
          height: 500px;
          object-fit: cover;
          border-radius: 15px;
          opacity: 1;
          transition: opacity 0.5s ease-in-out;
        }

        .main-slider-image.fade-out {
          opacity: 0;
        }

        .main-slider-image.fade-in {
          opacity: 1;
        }

            /* Left Content: Title, Description, Sections */
  .wpo-project-single-content-des-left p,
  .wpo-project-single-content-des-left ul,
  
  /* Right Content: Date, Location, Duration */
  .wpo-project-single-content-des-right ul,
  .wpo-project-single-content-des-right li {
    font-size: 20px !important; /* Increase font size */
    line-height: 1.6; /* Adjust line spacing */
  }

        
        .slider-arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background: rgba(0, 0, 0, 0.5);
          color: white;
          border: none;
          padding: 10px 20px;
          cursor: pointer;
          font-size: 18px;
          border-radius: 5px;
        }

        .slider-arrow.left-arrow {
          left: 10px;
        }

        .slider-arrow.right-arrow {
          right: 10px;
        }

        .slider-arrow:hover {
          background: rgba(0, 0, 0, 0.8);
        }

        .arrow-btn {
          background: linear-gradient(145deg, #003366, #004080); 
          color: white;
          border: none;
          padding: 12px 24px;
          border-radius: 25px;
          font-size: 16px;
          cursor: pointer;
        }

        .more-posts {
          display: flex;
          justify-content: space-between;
          margin-top: 30px;
        }
      `}</style>
    </section>
  );
};

export default Trainigprograms2022;
