import React, { Component } from "react";
import { Collapse, CardBody, Card } from "reactstrap";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next"; // Import translation HOC
import "./style.css";

// Menu Structure
const menus = [
  {
    id: 1,
    title: "home", // Use translation key
    link: "/home",
  },
  {
    id: 2,
    title: "about", // Use translation key
    link: "/about",
  },
  {
    id:9,
    title: "partnersPageTitle",
    link : "/ourpartners"
  },
  {
    id: 3,
    title: "services", // Use translation key
    submenu: [
      {
        id: 33,
        title: "trainingPrograms",
        link: "/trainingprograms",
        submenu: [
          {
            id: 331,
            title: "trainingPrograms2024Label",
            link: "/trainingprograms2024",
          },
          {
            id: 332,
            title: "trainingPrograms2023Label",
            link: "/trainingprograms2023",
          },
          {
            id: 333,
            title: "trainingPrograms2022Label",
            link: "/trainingprograms2022",
          },
        ],
      },
      {
        id: 34,
        title: "forums",
        link: "/forums",
      },
      {
        id: 35,
        title: "conferences",
        link: "/conferences",
      },
    ],
  },
  {
    id: 4,
    title: "media",
    link: "/media",
  },
  {
    id: 7,
    title: "ourClients",
    submenu: [
      {
        id: 71,
        title: "clients",
        link: "/clients",
      },
      {
        id: 75,
        title: "testimonials",
        link: "/testimonials",
      },
    ],
  },
  {
    id: 100,
    title: "trainingPlans",
    link: "/trainingplan",
    submenu: [
      {
        id: 66,
        title: "trainingPlan2025",
        link: "/trainingplan",
      },
      {
        id: 71,
        title: "trainingPlan2024",
        link: "/../../files/Mafaz_Training_plan_2024.pdf",
      },
      {
        id: 75,
        title: "trainingPlan2023",
        link: require("../../files/Mafaz_Training_plan_2023.pdf"),
        download: true,
        filename: "Mafaz_Training_plan_2023.pdf",
      },
    ],
  },
  {
    id: 88,
    title: "contactUs",
    link: "/contact",
  },
];

class MobileMenu extends Component {
  state = {
    isMenuShow: false,
    openMenus: {}, // Track open/close state of menus and submenus
  };

  menuHandler = () => {
    this.setState({
      isMenuShow: !this.state.isMenuShow,
    });
  };

  toggleMenu = (id) => {
    this.setState((prevState) => ({
      openMenus: {
        ...prevState.openMenus,
        [id]: !prevState.openMenus[id], // Toggle specific menu/submenu
      },
    }));
  };

  render() {
    const { isMenuShow, openMenus } = this.state;
    const { t } = this.props; // Translation function

    const ClickHandler = () => {
      window.scrollTo(10, 0);
    };

    return (
      <div>
        <div className={`mobileMenu ${isMenuShow ? "show" : ""}`}>
          <div className="menu-close">
            <div className="clox" onClick={this.menuHandler}>
              <i className="ti-close"></i>
            </div>
          </div>

          <ul className="responsivemenu">
            {menus.map((item) => (
              <li key={item.id}>
                {item.submenu ? (
                  <>
                    <p onClick={() => this.toggleMenu(item.id)}>
                      {t(item.title)} {/* Translate menu title */}
                      <i
                        className={`fa fa-angle-right ${
                          openMenus[item.id] ? "active" : ""
                        }`}
                      ></i>
                    </p>
                    <Collapse isOpen={!!openMenus[item.id]}>
                      <Card>
                        <CardBody>
                          <ul>
                            {item.submenu.map((submenu) => (
                              <li key={submenu.id}>
                                {submenu.submenu ? (
                                  <>
                                    <p
                                      onClick={() =>
                                        this.toggleMenu(submenu.id)
                                      }
                                    >
                                      {t(submenu.title)} {/* Translate submenu */}
                                      <i
                                        className={`fa fa-angle-right ${
                                          openMenus[submenu.id] ? "active" : ""
                                        }`}
                                      ></i>
                                    </p>
                                    <Collapse isOpen={!!openMenus[submenu.id]}>
                                      <Card>
                                        <CardBody>
                                          <ul>
                                            {submenu.submenu.map((nested) => (
                                              <li key={nested.id}>
                                                <Link
                                                  onClick={ClickHandler}
                                                  to={nested.link}
                                                >
                                                  {t(nested.title)}
                                                </Link>
                                              </li>
                                            ))}
                                          </ul>
                                        </CardBody>
                                      </Card>
                                    </Collapse>
                                  </>
                                ) : submenu.download ? (
                                  <a
                                    href={submenu.link}
                                    download={submenu.filename}
                                    className="download-link"
                                  >
                                    {t(submenu.title)} {/* Translate */}
                                  </a>
                                ) : (
                                  <Link
                                    onClick={ClickHandler}
                                    to={submenu.link}
                                  >
                                    {t(submenu.title)} {/* Translate */}
                                  </Link>
                                )}
                              </li>
                            ))}
                          </ul>
                        </CardBody>
                      </Card>
                    </Collapse>
                  </>
                ) : (
                  <Link onClick={ClickHandler} to={item.link}>
                    {t(item.title)} {/* Translate */}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>

        <div className="showmenu" onClick={this.menuHandler}>
          <button type="button" className="navbar-toggler open-btn">
            <span className="icon-bar first-angle"></span>
            <span className="icon-bar middle-angle"></span>
            <span className="icon-bar last-angle"></span>
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MobileMenu);
