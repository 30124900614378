import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Logo from "../../images/logo.webp";
import { Link } from "react-router-dom";
import MobileMenu from "../../components/MobileMenu";
import trainingPlan2023 from "../../files/Mafaz_Training_plan_2023.pdf";
import trainingPlan2024 from "../../files/Mafaz_Training_plan_2024.pdf";

import "./Header.css";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrolled: false,
    };
    // Bind changeLanguage to this
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  handleScroll = () => {
    const isScrolled = window.scrollY > 80;
    if (isScrolled !== this.state.isScrolled) {
      this.setState({ isScrolled });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  ClickHandler = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  preventClick = (e) => {
    e.preventDefault();
  };

  changeLanguage(lang) {
    const { i18n } = this.props;
    i18n.changeLanguage(lang);
  }

  downloadTrainingPlan = (label) => {
    let fileUrl;
    switch (label) {
      case "trainingPlan2023":
        fileUrl = trainingPlan2023;
        break;
      case "trainingPlan2024":
        fileUrl = trainingPlan2024;
        break;
      default:
        console.error("Invalid training plan label provided!");
        return;
    }

    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = `${label}.pdf`;
    link.click();
  };

  render() {
    const { t, i18n } = this.props;
    const { isScrolled } = this.state;
    const isRTL = i18n.language === "ar";

    return (
      <>
        <header
          id="header"
          className={`wpo-site-header ${isScrolled ? "scrolled" : ""}`}
          dir={isRTL ? "rtl" : "ltr"}
        >
          <nav className="navigation navbar navbar-expand-lg navbar-light">
            <div className="full-width-container">
              <div
                className={`row align-items-center no-gutters ${
                  isRTL ? "rtl" : ""
                }`}
              >
                <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                  <div className="mobail-menu">
                    <MobileMenu />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-6">
                  <div className="navbar-header">
                    <Link
                      onClick={this.ClickHandler}
                      className="navbar-brand"
                      to="/home"
                    >
                      <img src={Logo} alt="Logo" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-7 col-md-1 col-1">
                  <div
                    id="navbar"
                    className="collapse navbar-collapse navigation-holder"
                  >
                    <ul
                      className={`nav navbar-nav mb-2 mb-lg-0 ${
                        isRTL ? "rtl-nav" : ""
                      }`}
                    >
                      <li>
                        <Link onClick={this.ClickHandler} to="/">
                          {t("home")}
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.ClickHandler} to="/about">
                          {t("about")}
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.ClickHandler} to="/ourpartners">
                          {t("partners")}
                        </Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link
                          to="#"
                          onClick={this.preventClick}
                          className="nav-link"
                        >
                          {t("services")}
                        </Link>
                        <ul className={`sub-menu`}>
                          <li className="menu-item-has-children">
                            <Link
                              to="#"
                              onClick={this.preventClick}
                              className="nav-link"
                            >
                               &nbsp; &nbsp;
                              {t("trainingPrograms")}
                            </Link>
                            <ul className="sub-sub-menu">
                              <li>
                                <Link
                                  onClick={this.ClickHandler}
                                  to="/trainingprograms2024"
                                >
                                  {t("trainingPrograms2024Label")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={this.ClickHandler}
                                  to="/trainingprograms2023"
                                >
                                  {t("trainingPrograms2023Label")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={this.ClickHandler}
                                  to="/trainingprograms2022"
                                >
                                  {t("trainingPrograms2022Label")}
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link onClick={this.ClickHandler} to="/forums">
                            &nbsp; &nbsp;
                              {t("forums")}
                            </Link>
                          </li>
                          <li>
                            <Link onClick={this.ClickHandler} to="/conferences">
                            &nbsp; &nbsp;
                              {t("conferences")}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link onClick={this.ClickHandler} to="/media">
                          {t("media")}
                        </Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link
                          onClick={this.ClickHandler}
                          to="/clients"
                          aria-expanded="false"
                        >
                          {t("ourClients")}
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link onClick={this.ClickHandler} to="/clients">
                              {t("clients")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={this.ClickHandler}
                              to="/testimonials"
                            >
                              {t("testimonials")}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <Link
                          to="/trainingplan2025" // Navigate to /trainingplan2025 when clicked
                          className="nav-link"
                        >
                          {t("trainingPlans")}
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/trainingplan2025">
                              {t("trainingplan2025")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() =>
                                this.downloadTrainingPlan("trainingPlan2024")
                              }
                              to="#"
                            >
                              {t("trainingPlan2024")}
                            </Link>
                          </li>
                          <li>
                            <Link
                              onClick={() =>
                                this.downloadTrainingPlan("trainingPlan2023")
                              }
                              to="#"
                            >
                              {t("trainingPlan2023")}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link onClick={this.ClickHandler} to="/contact">
                          {t("contactUs")}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-2 text-end">
                  <button
                    className="btn btn-outline-primary mx-1 lang-btn"
                    onClick={() => this.changeLanguage("en")}
                  >
                    English
                  </button>
                  <button
                    className="btn btn-outline-primary mx-1 lang-btn"
                    onClick={() => this.changeLanguage("ar")}
                  >
                    العربية
                  </button>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <div className="header-spacer"></div>
      </>
    );
  }
}

export default withTranslation()(Header);
