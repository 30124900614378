import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./MediaGallery.css";

const imageCache = new Set();

const LazyImage = ({ src, alt, className }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!imageCache.has(src)) {
              const img = new Image();
              img.src = src;
              img.onload = () => {
                imageCache.add(src);
                setIsLoaded(true);
              };
            } else {
              setIsLoaded(true);
            }
            observer.disconnect();
          }
        });
      },
      { rootMargin: "200px 0px" }
    );

    if (imgRef.current) observer.observe(imgRef.current);
    return () => observer.disconnect();
  }, [src]);

  return (
    <div ref={imgRef} className={`lazy-image-container ${className}`}>
      {isLoaded ? (
        <img src={src} alt={alt} className="gallery-img" />
      ) : (
        <div className="image-placeholder" />
      )}
    </div>
  );
};

const Lightbox = ({ selectedImg, onClose, altText }) => {
  if (!selectedImg) return null;

  return (
    <div
      className="lightbox"
      onClick={onClose}
      role="dialog"
      aria-modal="true"
      aria-label="Enlarged Image"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Escape" || e.key === "Enter" || e.key === " ") {
          onClose();
        }
      }}
    >
      <span className="close" onClick={onClose} tabIndex={0} aria-label="Close lightbox">
        &times;
      </span>
      <img className="lightbox-content" src={selectedImg} alt={altText} />
    </div>
  );
};

const MediaGallery = () => {
  const { t } = useTranslation();
  const [selectedImg, setSelectedImg] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [transitionStage, setTransitionStage] = useState("in");
  const transitionDuration = 200;
  
  const allImages = Array.from({ length: 102 }, (_, i) => 
    require(`../../images/gallery/image${i + 1}.webp`)
  );

  const pageSize = 9;
  const totalPages = Math.ceil(allImages.length / pageSize);
  const currentPageImages = allImages.slice(
    currentPage * pageSize,
    currentPage * pageSize + pageSize
  );

  useEffect(() => {
    const preloadImages = () => {
      const nextPageStart = (currentPage + 1) * pageSize;
      const nextPageImages = allImages.slice(nextPageStart, nextPageStart + pageSize);
      nextPageImages.forEach((img) => {
        if (!imageCache.has(img)) {
          new Image().src = img;
        }
      });
    };
    if (currentPage < totalPages - 1) preloadImages();
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    if (transitionStage === "in") {
      setTransitionStage("out");
      setTimeout(() => {
        setCurrentPage(newPage);
        requestAnimationFrame(() => {
          setTransitionStage("in");
        });
      }, transitionDuration);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage === 0) return;
    handlePageChange(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage === totalPages - 1) return;
    handlePageChange(currentPage + 1);
  };

  return (
    <section className="media-gallery-section">
      <div className="container">
        <div className="gallery-header">
          <h2>{t("mediaGallery.title")}</h2>
        </div>

        <div className="pagination-container">
          <button
            className="arrow-btn prev-btn"
            onClick={handlePreviousPage}
            disabled={currentPage === 0 || transitionStage === "out"}
            tabIndex={0}
            aria-label="Previous Page"
          >
            &larr; {t("previousLabel")}
          </button>

          <div className={`gallery-grid ${transitionStage === "out" ? "fade-out" : "fade-in"}`}>
            {currentPageImages.map((img, index) => (
              <div
                className="gallery-item"
                key={index}
                onClick={() => setSelectedImg(img)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") setSelectedImg(img);
                }}
                tabIndex={0}
                aria-label={`Open image #${currentPage * pageSize + index + 1}`}
              >
                <LazyImage
                  src={img}
                  alt={`${t("mediaGallery.imageAlt")} ${currentPage * pageSize + index + 1}`}
                  className="gallery-img-container"
                />
              </div>
            ))}
          </div>

          <button
            className="arrow-btn next-btn"
            onClick={handleNextPage}
            disabled={currentPage === totalPages - 1 || transitionStage === "out"}
            tabIndex={0}
            aria-label="Next Page"
          >
            {t("nextLabel")} &rarr;
          </button>
        </div>

        <Lightbox
          selectedImg={selectedImg}
          onClose={() => setSelectedImg(null)}
          altText={t("mediaGallery.lightboxAlt")}
        />
      </div>
    </section>
  );
};

export default MediaGallery;