import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle';
import OurPartners from '../../components/OurPartners';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

const PartnersPage = () => {
  const { i18n } = useTranslation();
  const isRTL = i18n.language === 'ar'; // Check if the current language is Arabic

  return (
    <Fragment>
      <div
        dir={isRTL ? 'rtl' : 'ltr'}
        style={{
          direction: isRTL ? 'rtl' : 'ltr', // Dynamically apply direction
        }}
      >
        <Navbar />
        <PageTitle
          pageTitle={i18n.t("partnersPageTitle")} // Translate the title
          pagesub={i18n.t("partnersPageSubtitle")} // Translate the subtitle
        />
        <OurPartners />
        {/* Prevent footer from being affected by RTL */}
        <div dir="ltr">
          <Footer />
        </div>
        <Scrollbar />
      </div>
    </Fragment>
  );
};

export default PartnersPage;
