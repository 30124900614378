import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PartnerLogo1 from "../../images/iab_logo.png";
import PartnerLogo2 from "../../images/iaap_logo.png";
import PartnerLogo3 from "../../images/pdi_logo.png";

const Section = styled.section`
  padding: 120px 20px;
  background-color: #f4f7fc;
  font-family: "Arial", sans-serif;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 4rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 60px;
  color: #003366;
`;

const PartnerSection = styled.div`
  display: flex;
  align-items: flex-start; // Changed from 'center' to 'flex-start'
  gap: 40px;
  margin-bottom: 80px;
  flex-wrap: wrap;
  flex-direction: row-reverse;
`;

const PartnerImage = styled.div`
  flex: 1;
  display: flex;
  justify-content: ${({ isRTL }) => (isRTL ? "flex-start" : "flex-end")};

  img {
    max-width: 300px;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

const PartnerContent = styled.div`
  flex: 2;
  text-align: ${({ isRTL }) => (isRTL ? "right" : "left")};

  h2 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #003366;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.4rem;
    color: #666;
    line-height: 1.8;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: disc;
    margin-left: ${({ isRTL }) => (isRTL ? "0" : "20px")};
    margin-right: ${({ isRTL }) => (isRTL ? "20px" : "0")};
    padding: 0;
  }

  li {
    margin-bottom: 10px;
    font-size: 1.4rem;
    color: #666;
  }
`;

const Divider = styled.hr`
  border: none;
  height: 2px;
  background: #003366;
  margin: 80px 0;
  width: 100%;
`;


const OurPartners = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "ar"; // Dynamically detect language

  // Helper function: if the translation equals the key then return null.
  const translateOrNull = (key) => {
    const translation = t(key);
    return translation === key ? null : translation;
  };

  const partners = [
    {
      logo: PartnerLogo1,
      title: translateOrNull("partnersPage.iab.title"),
      description: [
        translateOrNull("partnersPage.iab.paragraph1"),
        translateOrNull("partnersPage.iab.paragraph2"),
        translateOrNull("partnersPage.iab.paragraph3"),
      ],
      list: [
        translateOrNull("partnersPage.iab.certification1"),
        translateOrNull("partnersPage.iab.certification2"),
        translateOrNull("partnersPage.iab.certification3"),
        translateOrNull("partnersPage.iab.certification4"),
      ],
      footerDescription: translateOrNull("partnersPage.iab.paragraph4"),
    },
    {
      logo: PartnerLogo2,
      title: translateOrNull("partnersPage.iaap.title"),
      description: [
        translateOrNull("partnersPage.iaap.paragraph1"),
        translateOrNull("partnersPage.iaap.paragraph2"),
        translateOrNull("partnersPage.iaap.paragraph3"),
      ],
      list: [
        translateOrNull("partnersPage.iaap.certification1"),
        translateOrNull("partnersPage.iaap.certification2"),
        translateOrNull("partnersPage.iaap.certification3"),
        translateOrNull("partnersPage.iaap.certification4"),
        translateOrNull("partnersPage.iaap.certification5"),
        translateOrNull("partnersPage.iaap.certification6"),
        translateOrNull("partnersPage.iaap.certification7"),
        translateOrNull("partnersPage.iaap.certification8"),
        translateOrNull("partnersPage.iaap.certification9"),
        translateOrNull("partnersPage.iaap.certification10"),
        translateOrNull("partnersPage.iaap.certification11"),
      ],
    },
    {
      logo: PartnerLogo3,
      title: translateOrNull("partnersPage.pdi.title"),
      description: [
        translateOrNull("partnersPage.pdi.paragraph1"),
        translateOrNull("partnersPage.pdi.paragraph2"),
        translateOrNull("partnersPage.pdi.paragraph3"),
      ],
      list: [
        translateOrNull("partnersPage.pdi.certification1"),
        translateOrNull("partnersPage.pdi.certification2"),
        translateOrNull("partnersPage.pdi.certification3"),
        translateOrNull("partnersPage.pdi.certification4"),
        translateOrNull("partnersPage.pdi.certification5"),
        translateOrNull("partnersPage.pdi.certification6"),
        translateOrNull("partnersPage.pdi.certification7"),
        translateOrNull("partnersPage.pdi.certification8"),
        translateOrNull("partnersPage.pdi.certification9"),
        translateOrNull("partnersPage.pdi.certification10"),
        translateOrNull("partnersPage.pdi.certification11"),
        translateOrNull("partnersPage.pdi.certification12"),
        translateOrNull("partnersPage.pdi.certification13"),
        translateOrNull("partnersPage.pdi.certification14"),
        translateOrNull("partnersPage.pdi.certification15"),
      ],
      footerDescription: translateOrNull("partnersPage.pdi.paragraph2"),
    },
  ];

  return (
    <Section>
      <Container>
        <Title>{translateOrNull("partnersPage.title")}</Title>
        {partners.map((partner, index) => (
          <React.Fragment key={index}>
            <PartnerSection>
              <PartnerImage isRTL={isRTL}>
                {partner.logo && (
                  <img src={partner.logo} alt={partner.title || "Partner Logo"} />
                )}
              </PartnerImage>
              <PartnerContent isRTL={isRTL}>
                {partner.title && <h2>{partner.title}</h2>}
                {partner.description &&
                  partner.description
                    .filter(Boolean)
                    .map((paragraph, idx) => <p key={idx}>{paragraph}</p>)}
                {partner.list &&
                  partner.list.filter(Boolean).length > 0 && (
                    <ul>
                      {partner.list.filter(Boolean).map((item, idx) => (
                        <li key={idx}>{item}</li>
                      ))}
                    </ul>
                  )}
                {partner.footerDescription && <p>{partner.footerDescription}</p>}
              </PartnerContent>
            </PartnerSection>
            {index < partners.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Container>
    </Section>
  );
};

export default OurPartners;
