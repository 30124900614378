import React from 'react';
import { useTranslation } from 'react-i18next';

const FunFact = (props) => {
  const { t } = useTranslation();

  const funfact = [
    {
      number: t('funfact.number1'),
      subTitle: t('funfact.clientSatisfaction'),
    },
    {
      number: t('funfact.number2'),
      subTitle: t('funfact.trainedProfessionals'),
    },
    {
      number: t('funfact.number3'),
      subTitle: t('funfact.repeatClients'),
    },
  ];

  return (
    <section className={`wpo-fun-fact-section ${props.fnClass}`}>
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div
              className="wpo-fun-fact-grids clearfix"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '40px',
              }}
            >
              {funfact.map((fact, index) => (
                <div className="grid" key={index}>
                  <div
                    className="info"
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column', // Vertically stack the number and text
                      alignItems: 'center', // Center align both items
                    }}
                  >
                    <h3>{fact.number}</h3>
                    <p>{fact.subTitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FunFact;
