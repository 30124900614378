import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle';
import About from '../../components/about';
import FunFact from '../../components/FunFact';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

const ContentWrapper = styled.div`
  font-family: ${({ lang }) => (lang === 'ar' ? "'Amiri', serif" : "'Arial', sans-serif")};
  font-size: ${({ lang }) => (lang === 'ar' ? '1.4rem' : '1rem')};
  direction: ${({ lang }) => (lang === 'ar' ? 'rtl' : 'ltr')};
  text-align: ${({ lang }) => (lang === 'ar' ? 'right' : 'left')};

  h1, h2, h3, h4, h5, h6, p, ul, li {
    font-family: ${({ lang }) => (lang === 'ar' ? "'Amiri', serif" : "'Arial', sans-serif")};
    text-align: ${({ lang }) => (lang === 'ar' ? 'right' : 'left')};
  }
`;

const AboutPage = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.body.setAttribute('lang', i18n.language);
  }, [i18n.language]);

  return (
    <Fragment>
      {/* Wrap only the content that needs language-specific styles */}
      <ContentWrapper lang={i18n.language}>
        <Navbar />
        <PageTitle
          pageTitle={t("aboutPageTitle")}
          pagesub={t("aboutPageSubtitle")}
        />
        <About abClass={'wpo-about-section-s2'} />
        <FunFact fnClass={'section-padding'} />
      </ContentWrapper>

      {/* Footer outside the styled wrapper */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default AboutPage;