import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// ====== Image Imports (same as before) ======
import prjImg30 from "../../images/training_programs_2024/Risk and Disaster Management/image1.jpg";
import prjImg31 from "../../images/training_programs_2024/Risk and Disaster Management/image2.jpg";

import prjImg1 from "../../images/training_programs_2024/The Integrated System Program for Building and Managing High-Performance Teams/image1.webp";
import prjImg2 from "../../images/training_programs_2024/The Integrated System Program for Building and Managing High-Performance Teams/image2.webp";
import prjImg20 from "../../images/training_programs_2024/The Integrated System Program for Building and Managing High-Performance Teams/image3.webp";
import prjImg29 from "../../images/training_programs_2024/The Integrated System Program for Building and Managing High-Performance Teams/image4.webp";

import prjImg3 from "../../images/training_programs_2024/Modern Technologies in Internal Auditing/image1.webp";
import prjImg9 from "../../images/training_programs_2024/Modern Technologies in Internal Auditing/image2.webp";
import prjImg10 from "../../images/training_programs_2024/Modern Technologies in Internal Auditing/image3.webp";
import prjImg11 from "../../images/training_programs_2024/Modern Technologies in Internal Auditing/image4.webp";

import prjImg4 from "../../images/training_programs_2024/Professional Leadership and Facing Challenges/image1.webp";
import prjImg12 from "../../images/training_programs_2024/Professional Leadership and Facing Challenges/image2.webp";
import prjImg13 from "../../images/training_programs_2024/Professional Leadership and Facing Challenges/image3.webp";
import prjImg14 from "../../images/training_programs_2024/Professional Leadership and Facing Challenges/image4.webp";

import prjImg5 from "../../images/training_programs_2024/Emergency Planning and Crisis Management/image1.webp";
import prjImg15 from "../../images/training_programs_2024/Emergency Planning and Crisis Management/image2.webp";
import prjImg16 from "../../images/training_programs_2024/Emergency Planning and Crisis Management/image3.webp";

import prjImg6 from "../../images/training_programs_2024/Utilizing Artificial Intelligence in the Workplace/image1.webp";
import prjImg7 from "../../images/training_programs_2024/Utilizing Artificial Intelligence in the Workplace/image2.webp";
import prjImg17 from "../../images/training_programs_2024/Utilizing Artificial Intelligence in the Workplace/image3.webp";
import prjImg18 from "../../images/training_programs_2024/Utilizing Artificial Intelligence in the Workplace/image4.webp";
import prjImg19 from "../../images/training_programs_2024/Utilizing Artificial Intelligence in the Workplace/image5.webp";

import prjImg21 from "../../images/training_programs_2024/Technical Inspection (VAT, Excise Tax, and Refund)/image1.webp";
import prjImg22 from "../../images/training_programs_2024/Technical Inspection (VAT, Excise Tax, and Refund)/image2.webp";
import prjImg23 from "../../images/training_programs_2024/Technical Inspection (VAT, Excise Tax, and Refund)/image3.webp";
import prjImg24 from "../../images/training_programs_2024/Technical Inspection (VAT, Excise Tax, and Refund)/image4.webp";

import prjImg25 from "../../images/training_programs_2024/Tax Evasion Techniques in Value-Added Tax (VAT) and Excise Tax/image1.webp";
import prjImg26 from "../../images/training_programs_2024/Tax Evasion Techniques in Value-Added Tax (VAT) and Excise Tax/image2.webp";
import prjImg27 from "../../images/training_programs_2024/Tax Evasion Techniques in Value-Added Tax (VAT) and Excise Tax/image3.webp";
import prjImg28 from "../../images/training_programs_2024/Tax Evasion Techniques in Value-Added Tax (VAT) and Excise Tax/image4.webp";
// Roles and Responsibilities of Internal Audit Managers and Heads According to International Standards
import prjImg33 from "../../images/training_programs_2024/Roles and Responsibilities of Internal Audit Managers and Heads According to International Standards/image1.jpeg";
import prjImg34 from "../../images/training_programs_2024/Roles and Responsibilities of Internal Audit Managers and Heads According to International Standards/image2.jpeg";
import prjImg35 from "../../images/training_programs_2024/Roles and Responsibilities of Internal Audit Managers and Heads According to International Standards/image3.jpg";
import prjImg36 from "../../images/training_programs_2024/Roles and Responsibilities of Internal Audit Managers and Heads According to International Standards/image4.jpg";

// Preload images to avoid flicker
const preloadImages = (imageUrls) => {
  imageUrls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

const Trainigprograms2024 = () => {
  const { t, i18n } = useTranslation();

  // Projects array, but we only translate text; no layout changes:
  const projects = [
    {
      images: [prjImg33, prjImg34, prjImg35, prjImg36],
      title: t("trainingPrograms2024.internalAuditRoles.title"),
      description: t("trainingPrograms2024.internalAuditRoles.description", {
        returnObjects: true,
      }),
      location: t("trainingPrograms2024.internalAuditRoles.location"),
      date: t("trainingPrograms2024.internalAuditRoles.date"),
      duration: t("trainingPrograms2024.internalAuditRoles.duration"),
    },
    {
      
      images: [prjImg30, prjImg31],
      title: t("trainingPrograms2024.riskAndDisasterManagement.title"),
      description: t("trainingPrograms2024.riskAndDisasterManagement.description", {
        returnObjects: true,
      }),
      location: t("trainingPrograms2024.riskAndDisasterManagement.location"),
      date: t("trainingPrograms2024.riskAndDisasterManagement.date"),
      duration: t("trainingPrograms2024.riskAndDisasterManagement.duration"),
    },
    {
      images: [prjImg1, prjImg2, prjImg20, prjImg29],
      title: t("trainingPrograms2024.theIntegratedSystem.title"),
      description: t("trainingPrograms2024.theIntegratedSystem.description", {
        returnObjects: true,
      }),
      location: t("trainingPrograms2024.theIntegratedSystem.location"),
      date: t("trainingPrograms2024.theIntegratedSystem.date"),
      duration: t("trainingPrograms2024.theIntegratedSystem.duration"),
    },
    {
      images: [prjImg3, prjImg9, prjImg10, prjImg11],
      title: t("trainingPrograms2024.modernTechnologies.title"),
      description: t("trainingPrograms2024.modernTechnologies.description", {
        returnObjects: true,
      }),
      location: t("trainingPrograms2024.modernTechnologies.location"),
      date: t("trainingPrograms2024.modernTechnologies.date"),
      duration: t("trainingPrograms2024.modernTechnologies.duration"),
    },
    {
      images: [prjImg4, prjImg12, prjImg13, prjImg14],
      title: t("trainingPrograms2024.professionalLeadership.title"),
      description: t("trainingPrograms2024.professionalLeadership.description", {
        returnObjects: true,
      }),
      location: t("trainingPrograms2024.professionalLeadership.location"),
      date: t("trainingPrograms2024.professionalLeadership.date"),
      duration: t("trainingPrograms2024.professionalLeadership.duration"),
    },
    {
      images: [prjImg5, prjImg15, prjImg16],
      title: t("trainingPrograms2024.emergencyPlanning.title"),
      description: t("trainingPrograms2024.emergencyPlanning.description", {
        returnObjects: true,
      }),
      location: t("trainingPrograms2024.emergencyPlanning.location"),
      date: t("trainingPrograms2024.emergencyPlanning.date"),
      duration: t("trainingPrograms2024.emergencyPlanning.duration"),
    },
    {
      images: [prjImg6, prjImg7, prjImg17, prjImg18, prjImg19],
      title: t("trainingPrograms2024.utilizingAiInWorkplace.title"),
      description: t("trainingPrograms2024.utilizingAiInWorkplace.description", {
        returnObjects: true,
      }),
      sections: t("trainingPrograms2024.utilizingAiInWorkplace.sections", {
        returnObjects: true,
      }),
      location: t("trainingPrograms2024.utilizingAiInWorkplace.location"),
      date: t("trainingPrograms2024.utilizingAiInWorkplace.date"),
      duration: t("trainingPrograms2024.utilizingAiInWorkplace.duration"),
    },
    {
      images: [prjImg21, prjImg22, prjImg23, prjImg24],
      title: t("trainingPrograms2024.technicalInspection.title"),
      description: t("trainingPrograms2024.technicalInspection.description", {
        returnObjects: true,
      }),
      location: t("trainingPrograms2024.technicalInspection.location"),
      date: t("trainingPrograms2024.technicalInspection.date"),
      duration: t("trainingPrograms2024.technicalInspection.duration"),
    },
    {
      images: [prjImg25, prjImg26, prjImg27, prjImg28],
      title: t("trainingPrograms2024.taxEvasion.title"),
      description: t("trainingPrograms2024.taxEvasion.description", {
        returnObjects: true,
      }),
      location: t("trainingPrograms2024.taxEvasion.location"),
      date: t("trainingPrograms2024.taxEvasion.date"),
      duration: t("trainingPrograms2024.taxEvasion.duration"),
    },
    
  ];

  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  // Preload images
  useEffect(() => {
    const allImages = projects.flatMap((p) => p.images || []);
    preloadImages(allImages);
  }, [projects]);

  const handlePreviousImage = () => {
    if (!isFading) {
      setIsFading(true);
      setTimeout(() => {
        setMainImageIndex((prev) =>
          prev === 0
            ? projects[currentProjectIndex].images.length - 1
            : prev - 1
        );
        setIsFading(false);
      }, 250);
    }
  };

  const handleNextImage = () => {
    if (!isFading) {
      setIsFading(true);
      setTimeout(() => {
        setMainImageIndex((prev) =>
          (prev + 1) % projects[currentProjectIndex].images.length
        );
        setIsFading(false);
      }, 250);
    }
  };

  const handlePreviousProject = () => {
    setCurrentProjectIndex((prev) =>
      prev === 0 ? projects.length - 1 : prev - 1
    );
    setMainImageIndex(0);
  };

  const handleNextProject = () => {
    setCurrentProjectIndex((prev) =>
      (prev + 1) % projects.length
    );
    setMainImageIndex(0);
  };

  // current project data
  const { images, title, description, location, sections, date, duration } =
    projects[currentProjectIndex];

  // We only set direction on TEXT-based elements, not the entire container,
  // so the layout doesn't shift to the right edge.
  const textDirectionStyle = {
    direction: i18n.language === "ar" ? "rtl" : "ltr",
    textAlign: i18n.language === "ar" ? "right" : "left",
  };

  return (
    <section className="wpo-project-single-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="wpo-project-single-wrap">
              <div className="wpo-project-single-content">
                {/* Image Slider */}
                <div className="main-image-container">
                  <button
                    className="slider-arrow left-arrow"
                    onClick={handlePreviousImage}
                  >
                    &larr;
                  </button>
                  <img
                    src={images[mainImageIndex]}
                    alt={`Project Slide ${mainImageIndex + 1}`}
                    className={`main-slider-image ${
                      isFading ? "fade-out" : "fade-in"
                    }`}
                  />
                  <button
                    className="slider-arrow right-arrow"
                    onClick={handleNextImage}
                  >
                    &rarr;
                  </button>
                </div>

                {/* Project Details */}
                <div className="wpo-project-single-content-des">
                  <div className="wpo-project-single-content-des-wrap">
                    {/* LEFT TEXT */}
                    <div
                      className="wpo-project-single-content-des-left"
                      style={textDirectionStyle}
                    >
                      <h2>{title}</h2>

                      {/* Description paragraphs */}
                      {description &&
                        description.map((para, idx) => (
                          <p key={idx}>{para}</p>
                        ))}

                      {/* Sections (for bullet points, etc.) */}
                      {sections &&
                        sections.map((section, sIdx) => (
                          <div key={sIdx} style={{ marginBottom: "20px" }}>
                            {section.header && (
                              <h3 style={{ marginBottom: "10px" }}>
                                {section.header}
                              </h3>
                            )}
                            {section.bulletPoints && (
                              <ul
                                style={{
                                  listStyleType: "disc",
                                  paddingInlineStart: "1.5rem",
                                }}
                              >
                                {section.bulletPoints.map((point, pIdx) =>
                                  typeof point === "object" &&
                                  point.subPoints ? (
                                    <li key={pIdx}>
                                      {point.mainPoint}
                                      <ul style={{ paddingInlineStart: "1.5rem" }}>
                                        {point.subPoints.map((sp, spIdx) => (
                                          <li key={spIdx}>{sp}</li>
                                        ))}
                                      </ul>
                                    </li>
                                  ) : (
                                    <li key={pIdx}>{point}</li>
                                  )
                                )}
                              </ul>
                            )}
                          </div>
                        ))}
                    </div>

                    {/* RIGHT TEXT (date/duration/location) */}
                    <div className="wpo-project-single-content-des-right">
                      <ul style={textDirectionStyle}>
                        <li>
                          {t("locationLabel")}: <span>{location}</span>
                        </li>
                        <li>
                          {t("dateLabel")}: <span>{date}</span>
                        </li>
                        <li>
                          {t("durationLabel")}: <span>{duration}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* Navigation Buttons */}
              <div className="more-posts">
                <button onClick={handlePreviousProject} className="arrow-btn prev-btn">
                  {/* Show arrow first, then text for previous */}
                  &larr; {t("previousLabel")}
                </button>
                <button onClick={handleNextProject} className="arrow-btn next-btn">
                  {/* Show text first, then arrow for next */}
                  {t("nextLabel")} &rarr;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Inline styles */}
      <style>{`
        .main-image-container {
          position: relative;
          width: 100%;
          max-height: 500px;
          overflow: hidden;
          border-radius: 15px;
        }

        .main-slider-image {
          width: 100%;
          height: 500px;
          object-fit: cover;
          border-radius: 15px;
          opacity: 1;
          transition: opacity 0.5s ease-in-out;
        }

        .main-slider-image.fade-out {
          opacity: 0;
        }

        .main-slider-image.fade-in {
          opacity: 1;
        }

        .slider-arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background: rgba(0, 0, 0, 0.5);
          color: white;
          border: none;
          padding: 10px 20px;
          cursor: pointer;
          font-size: 18px;
          border-radius: 5px;
        }

        .slider-arrow.left-arrow {
          left: 10px;
        }

        .slider-arrow.right-arrow {
          right: 10px;
        }
            /* Left Content: Title, Description, Sections */
  .wpo-project-single-content-des-left p,
  .wpo-project-single-content-des-left ul,
  
  /* Right Content: Date, Location, Duration */
  .wpo-project-single-content-des-right ul,
  .wpo-project-single-content-des-right li {
    font-size: 20px !important; /* Increase font size */
    line-height: 1.6; /* Adjust line spacing */
  }

        .slider-arrow:hover {
          background: rgba(0, 0, 0, 0.8);
        }

        .arrow-btn {
          background: linear-gradient(145deg, #003366, #004080);
          color: white;
          border: none;
          padding: 12px 24px;
          border-radius: 25px;
          font-size: 16px;
          cursor: pointer;
        }

        .more-posts {
          display: flex;
          justify-content: space-between;
          margin-top: 30px;
        }
      `}</style>
    </section>
  );
};

export default Trainigprograms2024;
