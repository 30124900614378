import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle';
import AllClients from '../../components/Clientpage';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar';

const Clientspage = () => {
  const { i18n } = useTranslation();
  const isRTL = i18n.language === 'ar'; // Check if the current language is Arabic

  return (
    <Fragment>
      <div
        dir={isRTL ? 'rtl' : 'ltr'}
        className={isRTL ? 'rtl' : ''}
        style={{
          direction: isRTL ? 'rtl' : 'ltr', // Set text direction dynamically
        }}
      >
        <Navbar />
        <PageTitle
          pageTitle={i18n.t("clientsPageTitle")}
          pagesub={i18n.t("clientsPageSubtitle")}
        />
        <AllClients />
        {/* Isolate Footer to prevent RTL/LTR issues */}
        <div dir="ltr">
          <Footer />
        </div>
        <Scrollbar />
      </div>
    </Fragment>
  );
};

export default Clientspage;
